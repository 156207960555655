<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }"
       style="background-color: #000000">
    <!-- 탱크재고 조회 --------------------------------------------------------------------------------------------->

    <div v-if="tanks.length" class="min-vh-100">

      <BCard v-for="(tanks, arCode) in filteredAreas"
           v-show="tanks.length"
           :key="arCode"
           style="min-width: 500px"
           border-variant="info"
           class="mb-1"
    >

      <div slot="header">
        <!--        <BIconOctagonFill  class="mr-1"/>-->

        <BButton class="ml-1 font-weight-bolder"
                 variant="outline-warning"
                 size="sm">
          <BIconBack/>
          {{ arCodeName[arCode] }} <strong v-if="isTankMode">{{tank?.name}}</strong> 모니터링
        </BButton>



        <div class="card-header-actions text-muted small">
          <BBadge variant="danger"
                  class="font-weight-bold font-lg mr-3"
                  :class="{blink:!$store.state.serverConnected}"
                  v-if="!$store.state.serverConnected">ATG 서버 연결 유실
          </BBadge>

          <BButton size="sm" class="mr-2"
                   :variant="$store.state.soundOn?'outline-info':'secondary'"
                   @click="() => $store.commit('toggle', 'soundOn')">
            <CIcon v-if="$store.state.soundOn" name="cil-volume-high"/>
            <CIcon v-else name="cil-volume-off"/>
            <span class="small ml-2"><b>SOUND {{$store.state.soundOn?'ON':'OFF'}}</b></span>
          </BButton>

          <BButton variant="outline-info" size="sm" @click="()=> $store.state.largeFont=true"  v-show="!$store.state.largeFont">
            <BIconPlusCircle/>
          </BButton>

          <BButton variant="outline-info" size="sm" @click="()=> $store.state.largeFont=false"  v-show="$store.state.largeFont">
            <BIconDashCircleFill/>
          </BButton>


<!--          <BBadge> {{ arCodeName[arCode] }} </BBadge> {{ arCodes[arCode]?arCodes[arCode].description:'' }}-->
        </div>

      </div>

      <BRow>
        <BCol v-for="tank in tanks" :key="tank.tid" style="min-width: 453px;max-width: 500px">
          <div v-if="tank.tankShape==='1'||tank.tankShape==='3'">
            <!-- 서보 및 라이다 타입 -->
            <Tank3DV2 v-if="tank.sensorType==='2'" :ref="tank.tid"
                    :width="300"
                    :height="250"
                    :tank="tank"
                    :bg-color="$store.state.darkMode? darkGray2:white"
            />

            <!--  ATG 매그니토스트릭티브 -->

            <Tank3D v-if="tank.sensorType==='1' && tank?.isTwin" :ref="tank.tid"
                      :width="300"
                      :height="250"
                      :tank="tank"
                    :bg-color="$store.state.darkMode? darkGray2:white"
            />

            <Tank2D v-if="tank.sensorType==='1' && !tank?.isTwin" :ref="tank.tid"
                    :width="300"
                    :height="250"
                    :tank="tank"
                    :bg-color="$store.state.darkMode? darkGray2:white"
            />

          </div>

          <!-- 가로 실린더 형 -->
          <div v-if="tank.tankShape==='2'">
            <Tank2D :ref="tank.tid"
                      :width="300"
                      :height="250"
                      :tank="tank"
                    :bg-color="$store.state.darkMode? darkGray2:white"
            >
            </Tank2D>
          </div>

          <!-- 탱크로리 형 -->
          <div v-if="tank.tankShape==='4'">
            <TankLorry :ref="tank.tid"
                    :width="300"
                    :height="250"
                    :tank="tank"
                    :bg-color="$store.state.darkMode? darkGray2:white"
            >
            </TankLorry>
          </div>

        </BCol>
      </BRow>

    </BCard>
    </div>

<!--    </BOverlay>-->
  </div>
</template>

<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import Tank3DV2 from '../components/tank-model/Tank3DV2';
import Tank2D from '../components/tank-model/Tank2D';
import Tank3D from '../components/tank-model/Tank3D';
import TankLorry from '../components/tank-model/TankLorry';
import {TankUseNameMap} from '@/common/constants';


import {
  apiCall, sleep, speech,
} from '@/common/utils';
// import qs from 'querystring';
// import moment from "moment";
import axios from "axios";


// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "TankMonitor",
  props: {
    win: { type: String, default: null},
  },
  components: {
    Tank3DV2,
    Tank3D,
    Tank2D,
    TankLorry
  },
  data () {
    return {
      loadingShow: true,
      loadingKey: 0,
      tp: null,
      areas: null,
      tanks: [],
      areaShow: {},
      tid: '',
      tids: [],
      tankOpts : this.$store.state.tanks['opts'],
      tankKey: 0,
      darkGray: '#181924',
      darkGray2: '#24252F',
      white: '#FFFFFF',
      maps: this.$store.state.codeMaps,
      selectedTank: null,
      selectedMachineId: null,
      tank : null,
      tlgPacket: {},
      tankInstances : {},

      downloadModalShow: false,
      machineMap  : {},
      oilCodeMap  : {},
      arCodeName  : this.$store.state.area['map'],
      arCodeMaps  : this.$store.state.area['maps'],
      areaOpts  : this.$store.state.area['opts'],
      arCodes : this.$store.state.area['codes'],
      arCode: '',
      downloadReason: '',
      searchOpts: [
        {value:'tid',        text:'탱크아이디'},
        {value:'name',       text:'탱크명'},
        {value:'arCode',     text:'지역코드'},
      ],
      searchField: 'tid',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,
      updateKey: 0,

      modalTitle: '',
      tankFormShow: false,
      socket: this.$store.state.socket,
      isAreaMode: false,
      isTankMode: false,

      listenerNameMap: {},
      listenerNames: [],

    }

  },
  async created(){
    try{
      this.loadingShow = true;

      // {value: r.arCode, text: map[r.arCode], label: map[r.arCode] }
      // console.log( 'tank options--->', this.tankOpts );

      this.isAreaMode = (this.$route.query.mode==='area');
      this.isTankMode = (this.$route.query.mode==='tank');


      // console.log( 'popup mode ----------------> ', this.$route.query.mode )

      let storedUser;

      if( window.storedUser ) {
        storedUser = JSON.parse(window.storedUser);
        // console.log("[pop-mode] window.storedUser--->", storedUser);
        axios.defaults.headers.common['x-access-token'] = storedUser.accessToken;
        await this.$store.commit('LOGIN', storedUser)
        this.arCode = this.$route.query.arCode;
        this.tid = this.$route.query.tid;
      }else{
        alert("[WARN] 인증 정보 없음. 재접속 요망")
        window.close();
        // await this.$router.push({name: 'login', params: { mode: 'pop', routeTo: '/monitoring',  query: this.$route.query } }, ()=>{}, ()=>{})
      }

/*
      if(!this.$store.state.socket){
        console.warn("[TankMonitor] ############ socket not exists...", this.$store.state.socket);
        // console.warn("[MONITOR] ############ waiting register handler...");
      }else{
        console.warn( "[TankMonitor] ############ socket object ----->", this.$store.state.socket);
        // console.warn("[MONITOR] ############ waiting register handler...");
      }
*/


    }catch(err){
      console.error(err);
      alert(err.message);
      window.close();
    }
    console.debug("----------------------- TankMonitor created -------------------------");
  },

  computed: {
    filteredAreas() {
      const userAreas = (JSON.parse(localStorage.getItem('user')) || [])?.areas || [];
      return Object.keys(this.areas)
        .filter(arCode => userAreas.includes(arCode))
        .reduce((obj, key) => {
          obj[key] = this.areas[key];
          return obj;
        }, {});
    }
  },

  async mounted() {
    try {
      await sleep(1000);
      this.arCodeName = this.$store.state.area['map'];
      this.arCodeMaps = this.$store.state.area['maps'];
      this.areaOpts = this.$store.state.area['opts'];
      this.arCodes = this.$store.state.area['codes'];

      await this.getTanks();

      this.loadingShow = false;

      await sleep(1000);

      await this.setSocketListener();

      for( const tid of this.tids ){

        if( Array.isArray(this.$refs[tid]) ) {
          this.tankInstances[tid] = this.$refs[tid][0];
        } else {
          this.tankInstances[tid] = this.$refs[tid];
        }
      }
      // console.log( 'tank instances ----> ', this.tankInstances );

    }catch(err){
      console.error('TankMonitor Mount error: ', err);
    }

  },

  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    if(this.socket) {

      for( const lnm of this.listenerNames){
        this.socket.removeListener(lnm, this.atgDataHandler);
        this.socket.on( lnm, this.atgDataHandler );
        console.warn("[TANK-MONITOR] beforeDestroy ____________ remove listeners ____________", lnm );
      }

      this.socket.removeListener('disconnect' );
      this.socket.removeListener('connect' );

    }
  },

  methods: {

    async setSocketListener(){
      try{

        this.socket = this.$store.state.socket;
        if(this.socket) {
          this.socket.removeListener('disconnect' );
          this.socket.removeListener('connect' );


          this.socket.on('disconnect', ()=>{
            // speech('ATG 서버와 통신이 유실 되었습니다. ,,화면을 종료하고 재접속 하시기 바랍니다.');
            speech('서버와 통신이 유실 되었습니다.');
            // modalWarn(this.$bvModal, '서버와 통신이 유실 되었습니다.');
            this.$store.state.serverConnected = false;
            // alert("[WARN] ATG 서버와 통신이 유실되었습니다. 모니터링을 종료합니다.")
            // window.close();
          });
          this.socket.on('connect', ()=>{
            speech('서버와 통신이 연결 되었습니다.');
            // modalSuccess(this.$bvModal, '서버와 통신이 연결 되었습니다.');
            this.$store.state.serverConnected = true;
          });
        }

        for( const lnm of this.listenerNames){
          this.socket.removeListener(lnm, this.atgDataHandler);
          this.socket.on( lnm, this.atgDataHandler );
          console.warn("[TANK-MONITOR] ____________ register Handler ____________", lnm ,"___________________")
        }


        console.warn( "[TANK-MONITOR] ____________ socket object ------> is connecting? ", this.socket.connected );

      }catch(err){
        console.error(err);
      }

    },


    atgDataHandler(data){
      // console.info('packet emitted --- data --->', data );
      // let tp = data.payload;
      // const tid = data.tid;
      // const instance = this.tankInstances[tid];

      // console.warn( instance );

      this.tankInstances[data.tid]?.setVolume(data);

    },

    async getTanks(){
      try{

        this.areas = {};
        const userRole = (JSON.parse(localStorage.getItem('user')) || [])?.role;
        if(!this.arCode){
          this.areaOpts.map( r=>{
            let code = r.value
            if( !code ) code = 'BASE';
            this.areas[code] = [];
          });
        }else{
          this.areas[this.arCode] = [];
          this.areaShow[this.arCode] = true;
        }

        let query = `order=arCode&arCode=${this.arCode}&tid=${this.tid}`

        this.tank = null;
        const r = await apiCall('get', `/api/tank?${query}`);
        let dispCount = 0;
        if(r.code===200){
          for( const t of r.result ){
            if(t.display){
              dispCount++;
              let arCode = t.arCode;
              if(!arCode) arCode = 'BASE';
              this.areas[arCode].push( t );
              this.tanks.push( t );
              this.tids.push( t.tid );
              // console.log( arCode, t );
              this.listenerNameMap[t.tankUse] = TankUseNameMap[t.tankUse];

            }
          }

          Object.keys(this.listenerNameMap).map(k=>{ this.listenerNames.push(this.listenerNameMap[k]) });
          console.warn( 'listenerNames____________', this.listenerNames );

          if((userRole === 'SA') || (userRole === 'SM')) {
          await this.toastInfo(`탱크 ${r.result.length}기 중 ${dispCount}기 표시`, 'info');
        }

          if(this.isTankMode) this.tank = this.tanks[0];

/*
          setTimeout(()=>{
            Object.keys( this.areas ).map(key=>{
              console.log( '------------ area key --->', key)

              this.areas[key].map(t=>{
                console.log( '-----------tankInstances set ------->', t.tid );
                // this.tankInstances[ t.tid ] = this.$refs[ t.tid ][0];
                // console.log( '-----------tankInstances ------->', this.tankInstances[ t.tid ] );

              });
            });


          }, 500 * r.result.length );
      */

          // console.log( 'task-data-query-result--->', r.result  );
        }

      }catch(err){
        // console.log( 'getTankList----------------->', err );
        this.toastError(err);
      }

    },

  }
}
</script>
